import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProgressIndicator from "../components/progressIndicator"
import config from "../../config.json"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <SEO
        title={`${frontmatter.title} | ${config.author}`}
        description={`Read ${config.author}'s ${frontmatter.title} for free, online, ebook!`}
      />
      <ProgressIndicator />
      <div className="book__container">
        <h1>{frontmatter.title}</h1>
        <h2>{config.author}</h2>
        <p className="book__src" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
