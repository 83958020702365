import React, { useEffect, useState } from "react"
import ThemeContext from "../context/theme"
import { Link } from "gatsby"

const ProgressIndicator = () => {
  const [width, setWidth] = useState(0)
  useEffect(() => {
    const handleScroll = event => {
      const percentage =
        (window.pageYOffset /
          (document.body.offsetHeight - window.innerHeight)) *
        100
      setWidth(percentage)
    }
    document.addEventListener("scroll", handleScroll)
    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])
  return (
    <ThemeContext.Consumer>
      {theme => (
        <div
          style={{
            backgroundColor: `white`,
            width: `100%`,
            position: "fixed",
            top: 0,
            left: 0,
          }}
        >
          <div
            style={{
              backgroundColor: theme.dark ? "white" : `#212529`,
              height: "10px",
              width: `${width}%`,
              position: "fixed",
              top: 0,
              left: 0,
            }}
          ></div>
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

export default ProgressIndicator
